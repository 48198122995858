import React from "react";
import { SContainer } from "../..";
import CodeBlock from "../containers/CodeBlock";
import SColumn from "../containers/SColumn";
import SRow from "../containers/SRow";
import SStud from "../containers/SStud";
import STitle from "../titles/STitle";

const RowsDoc = () => {
  return (
    <div>
      {" "}
      <SContainer>
        <SRow textAlign="left">
          <SColumn>
            <SStud>
              <STitle
                fontFamily="Courier New"
                color="blue"
                display="block"
                fontSize="2.3rem"
                fontWeight="600"
              >
                Rows
              </STitle>
              <STitle>
                Rows have the default css of text-align:center and display
                block.
              </STitle>
            </SStud>
          </SColumn>
          <SRow color="white" backgroundColor="blue">
            Row one
          </SRow>
          <SRow
            lineHeight="5rem"
            height="5rem"
            color="white"
            backgroundColor="green"
          >
            {" "}
            Row two
          </SRow>
          <SRow color="white" backgroundColor="red">
            {" "}
            Row three
          </SRow>
          <SRow color="white" backgroundColor="orange">
            {" "}
            Row four
          </SRow>
        </SRow>
        <SRow>
          <CodeBlock width="70rem" textAlign="left">
            import &#123;SRow&#125; from "stainless-steel";
            <br />
            ...
            <br />
            &#60;SRow color="white" backgroundColor="blue"&#62; Row one
            &#60;/SRow&#62; <br /> &#60;SRow lineHeight="5rem" height="5rem"
            color="white" backgroundColor="green"&#62; Row two &#60;/SRow&#62;
            <br />
            &#60;SRow color="white" backgroundColor="red"&#62; Row three
            &#60;/SRow&#62; <br /> &#60;SRow color="white"
            backgroundColor="orange"&#62; Row four &#60;/SRow&#62;
            <br />
            &#60;/CodeBlock&#62; &#60;/SRow&#62;
          </CodeBlock>
        </SRow>
      </SContainer>
    </div>
  );
};

export default RowsDoc;
