import React from "react";
import { SContainer } from "../..";
import SColumn from "../containers/SColumn";
import SRow from "../containers/SRow";
import SStud from "../containers/SStud";
import STitle from "../titles/STitle";

import testImg from "../../imgs/Mona_Lisa.jpeg";
import { Link } from "react-router-dom";
import CodeBlock from "../containers/CodeBlock";

const ContainerDoc = (props) => {
  return (
    <div>
      <SContainer>
        <SRow textAlign="left">
          <SColumn>
            <SStud>
              <STitle
                fontFamily="Courier New"
                color="blue"
                display="block"
                fontSize="2.3rem"
                fontWeight="600"
              >
                Containers
              </STitle>
              <STitle>
                Containers are one of the more simple concepts in
                stainless-steel. It starts off with the className of SContainer
                which has a width of 100% and a min-height of 100vh and a
                max-height of 100%.
              </STitle>
            </SStud>
          </SColumn>
          <SColumn>
            <SContainer
              overflow="auto"
              //className="SContainer-home"
              backgroundConfig={{
                img: testImg,
                width: "20rem",
                height: "30rem",
              }}
            >
              <STitle marginTop="25rem" color="lightblue">
                hello
              </STitle>
            </SContainer>
          </SColumn>
        </SRow>
        <SRow>
          <CodeBlock width="70rem" textAlign="left">
            import &#123;SContainer&#125; from "stainless-steel";
            <br />
            ...
            <br />
            &#60;SContainer overflow="auto" backgroundConfig=&#123;&#123; img:
            testImg, width: "20rem", height: "30rem", &#125;&#125; &#62; <br />{" "}
            &nbsp;&#60;STitle marginTop="25rem" color="lightblue"&#62; hello
            &#60;/STitle&#62; <br /> &#60;/SContainer&#62;
          </CodeBlock>
        </SRow>
      </SContainer>
    </div>
  );
};

export default ContainerDoc;
