import React from "react";
import { Yes } from "..";
import { SButton } from "..";
import CodeBlock from "./containers/CodeBlock";
import SContainer from "./containers/SContainer";
import SRow from "./containers/SRow";
//import SStud from "./containers/SStud";
import Simg from "./imgs/Simg";
import STitle from "./titles/STitle";
import {
  FiAnchor,
  FiAperture,
  FiAward,
  FiCommand,
  IconName,
} from "react-icons/fi";
import SHeader from "./Navigation/SHeader";
import { Link } from "react-router-dom";
import GoogleFontLoader from "react-google-font-loader";

import stainlesslogo from "../imgs/stainless-logo.png";

import { SStud } from "stainless-steel";

const Project = () => {
  const theFont = { fontSize: "1.2rem" };
  return (
    <div>
      <GoogleFontLoader
        fonts={[
          {
            font: "Kdam Thmor Pro",
            weights: [400],
          },
        ]}
      />
      <SContainer marginTop="0" className="SContainer-home">
        <SRow margin="2.5rem" marginBottom="0" marginTop="6rem">
          <SStud
            textAlign="center"
            backgroundColor="rgba(255, 255, 255, 0.767)"
            verticalAlign="middle"
            borderRadius="5rem"
            margin="2.5rem"
          >
            <Simg imgSrc={stainlesslogo} />
          </SStud>
          <SStud
            textAlign="center"
            margin="2.5rem"
            backgroundColor="rgba(255, 255, 255, 0.767)"
            verticalAlign="middle"
            //marginLeft="3rem"
          >
            <STitle
              fontWeight="600"
              color="rgb(89, 0, 190)"
              fontFamily="Courier New"
            >
              stainless-steel
            </STitle>
            <STitle>
              react components with built in styles and quick styles that can be
              defined through calling attributes.
            </STitle>
          </SStud>
        </SRow>
        <SRow marginBottom="0">
          <SStud
            backgroundColor="rgba(255, 255, 255, 0.767)"
            height="17rem"
            fontSize={"1.4rem"}
            verticalAlign={"top"}
            width="24rem"
            margin="2.5rem"
            padding="4rem"
            paddingTop="2rem"
          >
            <div
              style={{ textAlign: "center", fontSize: "3rem", padding: "1rem" }}
            >
              <FiAward />
            </div>
            <STitle fontWeight="600" color="blue" fontFamily="Courier New">
              Read it!
            </STitle>
            <STitle>
              Clear conceptual styles and components. We're here to help.
            </STitle>
          </SStud>
          <SStud
            margin="2.5rem"
            width="24rem"
            backgroundColor="rgba(255, 255, 255, 0.767)"
            height="17rem"
            fontSize={"1.4rem"}
            verticalAlign={"top"}
            padding="4rem"
            paddingTop="2rem"
          >
            <div
              style={{ textAlign: "center", fontSize: "3rem", padding: "1rem" }}
            >
              <FiCommand />
            </div>
            <STitle fontWeight="600" color="blue" fontFamily="Courier New">
              Pliant.
            </STitle>
            <STitle>
              The default styles scale with the size of the browser.
            </STitle>
          </SStud>
          <SStud
            margin="2.5rem"
            width="24rem"
            backgroundColor="rgba(255, 255, 255, 0.767)"
            height="17rem"
            fontSize={"1.4rem"}
            verticalAlign={"top"}
            padding="4rem"
            paddingTop="2rem"
            // padding="5rem"
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "3rem",
                padding: "1rem",
              }}
            >
              <FiAnchor />
            </div>
            <STitle fontWeight="600" color="blue" fontFamily="Courier New">
              You've seen it before.
            </STitle>
            <STitle>
              Simple css language concepts, streamlined so you can get through
              your project in clover.
            </STitle>
          </SStud>
        </SRow>
        <SRow marginBottom="0">
          <SStud styles={{ textAlign: "center" }}>
            <CodeBlock>npm i stainless-steel</CodeBlock>
          </SStud>
        </SRow>
      </SContainer>
    </div>
  );
};

export default Project;
