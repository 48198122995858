import React from "react";
import CodeBlock from "../containers/CodeBlock";
import SColumn from "../containers/SColumn";
import SRow from "../containers/SRow";
import SStud from "../containers/SStud";
import Simg from "../imgs/Simg";
import SLabel from "../titles/SLabel";
import STitle from "../titles/STitle";
import stainlesslogo from "../../imgs/stainless-logo.png";

const IntroDoc = (props) => {
  return (
    <div>
      <SRow
        textAlign="left"
        margin="3rem"
        marginLeft="5.5rem"
        fontSize="1.3rem"
      >
        <SColumn textAlign="center">
          <SStud>
            <STitle
              width="33rem"
              fontSize="2.3rem"
              fontWeight="500"
              marginBottom="2rem"
            >
              This website, was built using stainless-steel!
            </STitle>
            <STitle marginBottom="2rem">
              Let's dive right into how
              <SLabel fontFamily="Andalé Mono" fontWeight="600">
                stainless-steel
              </SLabel>
              components are built so you know how you're going to go about
              making application's with our package.
            </STitle>
          </SStud>
          <Simg imgSrc={stainlesslogo} />
        </SColumn>
      </SRow>
      <hr style={{ maxWidth: "69rem", margin: "auto" }} />
      <SRow
        textAlign="left"
        margin="3rem"
        marginLeft="5.5rem"
        fontSize="1.3rem"
      >
        <SColumn>
          <SStud>
            <STitle
              width="fit-content"
              fontSize="2rem"
              fontWeight="600"
              color="#3333FF"
              marginBottom="2rem"
              fontFamily="Courier New"
            >
              className
            </STitle>
            <STitle
              width="33rem"
              //fontSize="2.3rem"
              //fontWeight="500"
              marginBottom="2rem"
            >
              stainless-steel utilizes useEffect to set the className of each
              component to either the default css of that component (outlined in
              each components documentation), or you can use className to
              identify other stainless-steel css, or even your own css.
            </STitle>
          </SStud>
        </SColumn>
        <SColumn>
          <CodeBlock textAlign="left">
            ...
            <br />
            const [className, setClassName] = useState("SCodeBlock");
            <br />
            useEffect(() =&gt; &#123;
            <br />
            if (!props.className) &#123;
            <br />
            return;
            <br />
            &#125; else if (props.className) &#123;
            <br />
            setClassName(props.className)
            <br />
            &#125;
            <br />
            &#125;, [props.className]);
            <br />
            ... <br /> return (<br />
            &lt;div <br />
            style=&#123;style&#125; // explained next
            <br />
            className=&#123;className&#125;&gt; <br />
            &#123;props.children&#125;
            <br />
            &lt;/div&gt;
            <br />
            );
            <br />
          </CodeBlock>
        </SColumn>
      </SRow>

      <SRow
        textAlign="left"
        margin="3rem"
        marginLeft="5.5rem"
        fontSize="1.3rem"
        verticalAlign="top"
      >
        <SColumn textAlign="left">
          <SStud>
            <STitle
              width="fit-content"
              fontSize="2rem"
              fontWeight="600"
              color="#3333FF"
              marginBottom="3rem"
              fontFamily="Courier New"
            >
              style & quick attributes
            </STitle>
            <STitle
              width="33rem"
              //fontSize="2.3rem"
              //fontWeight="500"
              marginBottom="2rem"
              lineHeight="3rem"
            >
              We identified key styles you may want to have on say your{" "}
              <SLabel codeblock> STitle </SLabel> components or{" "}
              <SLabel codeblock>SButton</SLabel> components that you can
              identify as attributes. We call these{" "}
              <SLabel
                fontWeight="600"
                color="rgb(250, 0, 0)"
                fontFamily="Courier New"
                marginRight="0rem"
                paddingRight="0"
                paddingLeft="0"
              >
                {" "}
                quick attributes
              </SLabel>
              . Each component has{" "}
              <SLabel
                fontWeight="600"
                color="rgb(250, 0, 0)"
                fontFamily="Courier New"
                marginRight="0rem"
                paddingRight="0"
                paddingLeft="0"
              >
                {" "}
                standard quick attributes
              </SLabel>
              , but there are also{" "}
              <SLabel
                fontWeight="600"
                color="rgb(250, 0, 0)"
                fontFamily="Courier New"
                marginRight="0rem"
                paddingRight="0"
                paddingLeft="0"
              >
                {" "}
                special quick attributes
              </SLabel>
              . The{" "}
              <SLabel
                fontWeight="600"
                color="rgb(250, 0, 0)"
                fontFamily="Courier New"
                paddingLeft="0"
              >
                {" "}
                standard quick attributes
              </SLabel>{" "}
              are listed below. While the{" "}
              <SLabel
                fontWeight="600"
                color="rgb(250, 0, 0)"
                fontFamily="Courier New"
              >
                {" "}
                special quick attributes
              </SLabel>{" "}
              will be listed in each components respective documentation.
            </STitle>
            <STitle lineHeight="3rem" marginBottom="15rem">
              You can also inline style stainless-steel components if theres
              something you really need that isn't a quick attribute.
              Technically you could morph all the components in the
              stainless-steel package into their peer components. This package
              is for pick up and go styles.
            </STitle>
          </SStud>
        </SColumn>
        <SColumn>
          <SStud padding="0rem">
            <SLabel marginTop="3rem" codeblock>
              STitle.js
            </SLabel>
            <CodeBlock width="42rem" textAlign="left">
              ...
              <br />
              const [style, setStyle] = useState();
              <br />
              useEffect(() =&gt; &#123;
              <br />
              if (!props.style) &#123;
              <br />
              return;
              <br />
              &#125; else if (props.style) &#123;
              <br />
              setStyle(props.style)
              <br />
              &#125;
              <br />
              &#125;, [props.style]);
              <br />
              ... <br />
              ...
              <br />
              // example quick attribute
              <br />
              useEffect(() =&gt; &#123;
              <br />
              if (!props.margin) &#123;
              <br />
              return;
              <br />
              else if (props.margin) &#123;
              <br />
              setStyle((style) =&gt; (&#123; <br />
              ...style,
              <br />
              ...aStyle, <br />
              &#125;));
              <br />
              &#125;
              <br />
              &#125;, [props.margin]);
              <br />
              ...
            </CodeBlock>

            <SLabel textAlign="left" codeblock>
              YourComponent.js
            </SLabel>
            <CodeBlock width="42rem" textAlign="left">
              <br /> return (<br />
              &lt;STitle <br />
              style=&#123;padding:"2rem"&#125;
              <br />
              margin="1rem"
              <br />
              className="any-class-name"&gt; <br />
              This looks great!
              <br />
              &lt;/div&gt;
              <br />
              );
              <br />
            </CodeBlock>
          </SStud>
        </SColumn>
      </SRow>
      <SRow
        textAlign="left"
        margin="3rem"
        marginLeft="5.5rem"
        fontSize="1.3rem"
        verticalAlign="top"
      >
        <hr />
        <SColumn>
          <SStud>
            <STitle
              width="40rem"
              fontSize="2rem"
              fontWeight="600"
              color="#3333FF"
              marginBottom="3rem"
              fontFamily="Courier New"
            >
              Standard Quick Attributes
            </STitle>
            <STitle>Stand Quick Attributes include...</STitle>
            <STitle
              fontSize="1.3rem"
              fontWeight="600"
              color="#3333FF"
              marginBottom="3rem"
              fontFamily="Courier New"
            >
              FontSize
              <br />
              fontWeight
              <br />
              fontFamily
              <br />
              verticalAlign
              <br />
              textAlign
              <br />
              margin
              <br />
              marginRight
              <br />
              marginTop
              <br />
              marginBottom
              <br />
              marginLeft
              <br />
              width
              <br />
              maxWidth
              <br />
              minWidth
              <br />
              height
              <br />
              maxHeight
              <br />
              minHeight
              <br />
              color
              <br />
              backgroundColor
              <br />
              lineHeight
              <br />
              padding
              <br />
              paddingTop
              <br />
              paddingRight
              <br />
              paddingBottom
              <br />
              paddingLeft
              <br />
              borderRadius <br />
            </STitle>
          </SStud>
        </SColumn>
      </SRow>
    </div>
  );
};

export default IntroDoc;
