import React from "react";
import { SButton, SContainer } from "..";
import CodeBlock from "./containers/CodeBlock";
import ColumnContainer from "./containers/ColumnContainer";
import SRow from "./containers/SRow";
import SStud from "./containers/SStud";
import SSidebar from "./Navigation/SSidebar";
import STitle from "./titles/STitle";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Routes,
} from "react-router-dom";
import ButtonsDoc from "./documentation/ButtonsDoc";

import { useParams } from "react-router";
import ContainerDoc from "./documentation/ContainerDoc";
import IntroDoc from "./documentation/IntroDoc";
import CardsDoc from "./documentation/CardsDoc";
import InputsDoc from "./documentation/InputsDoc";
import TitlesDoc from "./documentation/TitlesDoc";
import SideBarDoc from "./documentation/SideBarDoc";
import HeaderDoc from "./documentation/HeaderDoc";
import StudsDoc from "./documentation/StudsDoc";
import RowsDoc from "./documentation/RowsDoc";
import ColumnsDoc from "./documentation/ColumnsDoc";

const Documentation = (props) => {
  const docParams = useParams().docId;

  console.log(docParams);
  return (
    <div>
      {(docParams || !docParams) && (
        <SContainer sidebar>
          <SSidebar className="SSidebar">
            <div
              style={{
                width: "12rem",
                textAlign: "left",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "3rem",
                fontSize: "2rem",
                textDecoration: "none",
                color: "rgb(0, 89, 190)",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "blue",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Intro"
              >
                intro
              </Link>

              <hr
                style={{
                  width: "12rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
              />
              <Link
                style={{
                  textDecoration: "none",
                  color: "rgb(15, 160, 39)",
                  display: "block",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Container"
              >
                Container
              </Link>
              <Link
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "rgb(15, 160, 39)",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Rows"
              >
                Rows
              </Link>
              <Link
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "rgb(15, 160, 39)",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Columns"
              >
                Columns
              </Link>

              <Link
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "rgb(15, 160, 39)",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Studs"
              >
                Studs
              </Link>
              {/* <Link
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "rgb(15, 160, 39)",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Header"
              >
                Header
              </Link>
              <Link
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "rgb(15, 160, 39)",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Sidebar"
              >
                Sidebar
              </Link> */}
              <Link
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "rgb(15, 160, 39)",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Cards"
              >
                Cards
              </Link>

              <Link
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "rgb(15, 160, 39)",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Buttons"
              >
                Buttons
              </Link>
              {/* <Link
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "rgb(15, 160, 39)",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Inputs"
              >
                Inputs
              </Link>
              <Link
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "rgb(15, 160, 39)",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/Titles"
              >
                Titles
              </Link> */}
              {/* <hr style={{ width: "12rem" }} />
              <Link
                style={{
                  textDecoration: "none",
                  color: "red",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  fontFamily: "Courier New",
                }}
                to="/documentation/quickattributes"
              >
                quick attributes
              </Link> */}
            </div>
          </SSidebar>
          <ColumnContainer>
            {docParams === "Intro" && <IntroDoc />}
            {docParams === "Container" && <ContainerDoc />}
            {docParams === "Rows" && <RowsDoc />}
            {docParams === "Columns" && <ColumnsDoc />}

            {docParams === "Studs" && <StudsDoc />}
            {docParams === "Header" && <HeaderDoc />}
            {docParams === "Sidebar" && <SideBarDoc />}
            {docParams === "Cards" && <CardsDoc />}
            {docParams === "Buttons" && <ButtonsDoc />}
            {docParams === "Inputs" && <InputsDoc />}
            {docParams === "Titles" && <TitlesDoc />}
          </ColumnContainer>
        </SContainer>
      )}
    </div>
  );
};

export default Documentation;
