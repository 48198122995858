import React from "react";
import SCard from "../cards/SCard";
import SColumn from "../containers/SColumn";
import SRow from "../containers/SRow";
import SStud from "../containers/SStud";
import Simg from "../imgs/Simg";
import STitle from "../titles/STitle";

import stainlesslogo from "../../imgs/stainless-logo.png";

import profilePic from "../../imgs/profilepicCards.JPG";

import { TiArrowForwardOutline } from "react-icons/ti";

import { FiMessageCircle, IconName } from "react-icons/fi";
import { IoFlameOutline } from "react-icons/io5";
import CodeBlock from "../containers/CodeBlock";

const CardsDoc = () => {
  return (
    <div>
      <SRow textAlign="left">
        <SColumn>
          <SStud>
            <STitle
              fontFamily="Courier New"
              color="blue"
              display="block"
              fontSize="2.3rem"
              fontWeight="600"
            >
              Cards
            </STitle>
            <STitle>
              Below are plain cards. SColumn is equipped with props.map,
              props.data, props.item, and props.items as an attribute for
              mapping values. All four can be used to select a stainless-steel
              components attributes. This comes in handy when mapping out cards.
            </STitle>
            <STitle>
              As you can see below. A column with a max-width flexes with the
              cards. The cards have some really simple css that is designed to
              center them on the page every new row.{" "}
            </STitle>
          </SStud>
        </SColumn>
      </SRow>
      <SRow backgroundColor="rgb(155, 155, 155">
        <SColumn
          backgroundColor="white"
          border="lightgrey .2rem solid"
          margin="4rem"
          maxWidth="85rem"
          minHeight="50rem"
          borderRadius="3rem"
        >
          <SCard></SCard>
          <SCard></SCard>
          <SCard></SCard>
        </SColumn>
      </SRow>

      {/* <SRow textAlign="left">
        <SColumn>
          <SStud>
            <STitle>Styles</STitle>
            <STitle>Check out some of our styles.</STitle>
          </SStud>
        </SColumn>
      </SRow> */}

      <SRow>
        <hr />
        <STitle
          marginTop="5rem"
          fontFamily="Courier New"
          color="blue"
          display="block"
          fontSize="2.3rem"
          fontWeight="600"
        >
          Pocket Knife
        </STitle>
        <SColumn
          backgroundColor="white"
          border="lightgrey .2rem solid"
          margin="4rem"
          maxWidth="85rem"
          minHeight="50rem"
          borderRadius="3rem"
        >
          <SCard getAnimation className="SCard-pocketKnife">
            <Simg imgSrc={stainlesslogo} />
            <div className="SCard-pocketKnife-bottleOpener">
              <div>price: $100 trillion</div>
              <hr />
              <div>What a nice logo</div>

              <div>beautiful day out, and a nice logo</div>
              <div>
                the leafs on the trees are green because its summer, but this is
                silver looking.
              </div>
            </div>
          </SCard>
          <SCard getAnimation className="SCard-pocketKnife">
            <Simg imgSrc={stainlesslogo} />
            <div className="SCard-pocketKnife-bottleOpener">
              <div>price: $99 trillion</div>
              <hr />
              <div>this is the stainless-steel logo, pretty fly</div>

              <div>this is the stainless-steel logo, pretty amazing</div>
              <div>this is the stainless-steel logo, awesome picture</div>
            </div>
          </SCard>
          <SCard getAnimation className="SCard-pocketKnife">
            <Simg imgSrc={stainlesslogo} />
            <div className="SCard-pocketKnife-bottleOpener">
              <div>price: $999 trillion</div>
              <hr />
              <div>we like logos, this one is stainless-steel</div>

              <div>we're focused with our brains on stainless steel</div>
              <div>study the logo</div>
            </div>
          </SCard>
        </SColumn>
        <SColumn>
          <CodeBlock width="80rem" textAlign="left">
            import &#123;SCard&#125; from "stainless-steel";
            <br />
            <br />
            ...
            <br />
            <br />
            &#60;SCard getAnimation className="SCard-pocketKnife"&#62;
            <br />
            &nbsp; &#60;Simg imgSrc="image-src.com/img.jpg" /&#62;
            <br />
            &nbsp;&nbsp;&#60;div className="SCard-pocketKnife-bottleOpener"&#62;
            <br />
            &nbsp;&nbsp;&nbsp;&#60;div&#62;price: $999 trillion&#60;/div&#62;
            &#60;hr /&#62; <br />
            &nbsp;&nbsp;&nbsp;&#60;div&#62;we like logos, this one is
            stainless-steel&#60;/div&#62;
            <br />
            &nbsp;&nbsp;&nbsp;&#60;div&#62;we're focused with our brains on
            stainless steel&#60;/div&#62; <br />
            &nbsp;&nbsp;&nbsp;&#60;div&#62;study the logo&#60;/div&#62; <br />
            &nbsp; &#60;/div&#62;
            <br /> &#60;/SCard&#62;
          </CodeBlock>
        </SColumn>
      </SRow>
      <SRow>
        <hr />
        <STitle
          marginTop="5rem"
          fontFamily="Courier New"
          color="blue"
          display="block"
          fontSize="2.3rem"
          fontWeight="600"
        >
          Book Shelf
        </STitle>
        <SColumn
          backgroundColor="white"
          border="lightgrey .2rem solid"
          margin="4rem"
          maxWidth="85rem"
          minHeight="50rem"
          borderRadius="3rem"
          textAlign="center"
        >
          <SCard className="SCard-bookShelf">
            <Simg imgSrc={stainlesslogo} />
            <div className="SCard-bookShelf-partition">
              <div>price: $99 trillion</div>
              <hr />
              <div>this is the stainless-steel logo, pretty fly</div>

              <div>this is the stainless-steel logo, pretty amazing</div>
              <div>this is the stainless-steel logo, awesome picture</div>
            </div>
          </SCard>
          <SCard className="SCard-bookShelf">
            <Simg imgSrc={stainlesslogo} />
            <div className="SCard-bookShelf-partition">
              <div>price: $100 trillion</div>
              <hr />
              <div>What a nice logo</div>

              <div>beautiful day out, and a nice logo</div>
              <div>
                the leafs on the trees are green because its summer, but this is
                silver looking.
              </div>
            </div>
          </SCard>
          <SCard className="SCard-bookShelf">
            <Simg imgSrc={stainlesslogo} />
            <div className="SCard-bookShelf-partition">
              <div>price: $999 trillion</div>
              <hr />
              <div>we like logos, this one is stainless-steel</div>

              <div>we're focused with our brains on stainless steel</div>
              <div>study the logo</div>
            </div>
          </SCard>
        </SColumn>
        <SColumn>
          <CodeBlock width="80rem" textAlign="left">
            import &#123;SCard&#125; from "stainless-steel";
            <br />
            <br />
            ...
            <br />
            <br />
            &#60;SCard className="SCard-bookShelf"&#62;
            <br />
            &nbsp;&#60;Simg imgSrc= /&#62;
            <br />
            &nbsp;&#60;div className="SCard-bookShelf-partition"&#62;
            <br />
            &nbsp;&nbsp; &#60;div&#62;price: $99 trillion&#60;/div&#62;
            <br />
            &nbsp;&#60;hr /&#62;
            <br />
            &nbsp;&nbsp; &#60;div&#62;this is the stainless-steel logo, pretty
            fly&#60;/div&#62;
            <br />
            &nbsp;&nbsp;&nbsp;&#60;div&#62;this is the stainless-steel logo,
            pretty amazing&#60;/div&#62;
            <br />
            &nbsp;&nbsp;&nbsp;&#60;div&#62;this is the stainless-steel logo,
            awesome picture&#60;/div&#62;
            <br />
            &nbsp; &#60;/div&#62;
            <br /> &#60;/SCard&#62;
          </CodeBlock>
        </SColumn>
      </SRow>
      <SRow>
        <hr />
        <STitle
          marginTop="5rem"
          fontFamily="Courier New"
          color="blue"
          display="block"
          fontSize="2.3rem"
          fontWeight="600"
        >
          Social Star
        </STitle>
        <SColumn
          marginTop="0rem"
          backgroundColor="white"
          border="lightgrey .2rem solid"
          margin="4rem"
          width="85rem"
          minHeight="50rem"
          borderRadius="3rem"
        >
          <SCard className="SCard-socialStar">
            <div className="SCard-socialStar-header">
              <img
                src={profilePic}
                alt="stainless-steel-card"
                className="SCard-socialStar-header-profilePicture"
              />
              <div className="SCard-socialStar-header-title"> Gavin Potter</div>
            </div>
            <div className="SCard-socialStar-postInfo">
              <div>Hello, I'm Gavin, this is my first post!</div>
              <div>June 32, 3092</div>
            </div>
            <div className="SCard-socialStar-postMoves">
              <div className="SCard-socialStar-postMoves-moves">
                <label className="postmoves-sizer">
                  <IoFlameOutline />
                </label>
                like
              </div>
              <div className="SCard-socialStar-postMoves-moves">
                <div className="postmoves-sizer">
                  <FiMessageCircle />
                </div>
                comment
              </div>
              <div className="SCard-socialStar-postMoves-moves">
                <div className="postmoves-sizer">
                  <TiArrowForwardOutline />
                </div>
                share
              </div>
            </div>
          </SCard>
          <SCard className="SCard-socialStar">
            <div className="SCard-socialStar-header">
              <img
                src={profilePic}
                alt="stainless-steel-card"
                className="SCard-socialStar-header-profilePicture"
              />
              <div className="SCard-socialStar-header-title"> Gavin Potter</div>
            </div>
            <div className="SCard-socialStar-postInfo">
              <div>
                Hello, I'm Gavin, this is my second post! The birds are chirping
                the sun is shining life is good.
              </div>
              <div>June 32, 3092</div>
            </div>
            <div className="SCard-socialStar-postMoves">
              <div className="SCard-socialStar-postMoves-moves">
                <label className="postmoves-sizer">
                  <IoFlameOutline />
                </label>
                like
              </div>
              <div className="SCard-socialStar-postMoves-moves">
                <div className="postmoves-sizer">
                  <FiMessageCircle />
                </div>
                comment
              </div>
              <div className="SCard-socialStar-postMoves-moves">
                <div className="postmoves-sizer">
                  <TiArrowForwardOutline />
                </div>
                share
              </div>
            </div>
          </SCard>
          <SCard className="SCard-socialStar">
            <div className="SCard-socialStar-header">
              <img
                src={profilePic}
                alt="stainless-steel-card"
                className="SCard-socialStar-header-profilePicture"
              />
              <div className="SCard-socialStar-header-title"> Gavin Potter</div>
            </div>
            <div className="SCard-socialStar-postInfo">
              <div>
                And, for an earnest of a greater honour, He bade me, from him,
                call thee thane of Cawdor: In which addition, hail, most worthy
                thane! For it is thine.
              </div>
              <div>June 32, 3092</div>
            </div>
            <div className="SCard-socialStar-postMoves">
              <div className="SCard-socialStar-postMoves-moves">
                <label className="postmoves-sizer">
                  <IoFlameOutline />
                </label>
                like
              </div>
              <div className="SCard-socialStar-postMoves-moves">
                <div className="postmoves-sizer">
                  <FiMessageCircle />
                </div>
                comment
              </div>
              <div className="SCard-socialStar-postMoves-moves">
                <div className="postmoves-sizer">
                  <TiArrowForwardOutline />
                </div>
                share
              </div>
            </div>
          </SCard>
        </SColumn>
        <SColumn>
          <CodeBlock width="80rem" textAlign="left">
            import &#123;SCard&#125; from "stainless-steel";
            <br />
            import &#123; TiArrowForwardOutline, &#125; from "react-icons/ti";
            <br />
            import &#123; FiMessageCircle, IconName &#125; from
            "react-icons/fi";
            <br /> import &#123; IoFlameOutline &#125; from "react-icons/io5";
            <br />
            <br />
            ...
            <br />
            <br /> &#60;SCard className="SCard-socialStar"&#62; <br /> &nbsp;
            &#60;div className="SCard-socialStar-header"&#62;
            <br />
            &nbsp; &nbsp; &#60;img src="image-src" alt="stainless-steel-card"
            className="SCard-socialStar-header-profilePicture" /&#62;
            <br />
            &nbsp; &nbsp; &#60;div
            className="SCard-socialStar-header-title"&#62; Gavin Potter
            &#60;/div&#62;
            <br /> &nbsp; &#60;/div&#62;
            <br /> &nbsp; &#60;div className="SCard-socialStar-postInfo"&#62;
            <br /> &nbsp; &nbsp; &#60;div&#62;Hello, I'm Gavin, this is my first
            post!&#60;/div&#62;
            <br /> &nbsp; &nbsp; &#60;div&#62;June 32, 3092&#60;/div&#62;
            <br /> &nbsp; &#60;/div&#62;
            <br /> &nbsp;&#60;div className="SCard-socialStar-postMoves"&#62;
            <br /> &nbsp;&#60;div
            className="SCard-socialStar-postMoves-moves"&#62;
            <br /> &nbsp; &nbsp; &#60;label className="postmoves-sizer"&#62;
            <br /> &nbsp; &nbsp; &nbsp; &#60;IoFlameOutline /&#62; // from
            react-icons npmjs package
            <br /> &nbsp; &nbsp; &#60;/label&#62; like <br /> &nbsp;
            &#60;/div&#62;
            <br /> &nbsp; &#60;div
            className="SCard-socialStar-postMoves-moves"&#62;
            <br /> &nbsp;&nbsp; &#60;div className="postmoves-sizer"&#62;
            <br /> &nbsp;&nbsp; &nbsp; &#60;FiMessageCircle /&#62;
            <br /> &nbsp;&nbsp; &#60;/div&#62; comment <br /> &nbsp;
            &#60;/div&#62;
            <br /> &nbsp; &#60;div
            className="SCard-socialStar-postMoves-moves"&#62; <br /> &nbsp;
            &nbsp; &#60;div className="postmoves-sizer"&#62;
            <br /> &nbsp; &nbsp; &nbsp;&#60;TiArrowForwardOutline /&#62;
            <br /> &nbsp; &nbsp; &#60;/div&#62; share <br /> &nbsp;
            &#60;/div&#62;
            <br /> &nbsp; &#60;/div&#62;
            <br /> &#60;/SCard&#62;
          </CodeBlock>
        </SColumn>
      </SRow>
    </div>
  );
};

export default CardsDoc;
