import React from "react";
import { SButton } from "../..";
import CodeBlock from "../containers/CodeBlock";
import SColumn from "../containers/SColumn";
import SRow from "../containers/SRow";
import SStud from "../containers/SStud";
import STitle from "../titles/STitle";

const ButtonsDoc = () => {
  return (
    <div>
      <SRow marginBottom="0" textAlign="left">
        <SStud>
          <STitle
            fontFamily="Courier New"
            color="blue"
            display="block"
            fontSize="2.3rem"
            fontWeight="600"
          >
            Buttons
          </STitle>
          <STitle>
            Generate dynamic buttons, with stainless-steel css. You can use
            attributes to alter styles and utilize effects.
          </STitle>
        </SStud>
      </SRow>

      <SRow marginTop="0">
        <SStud height="fit-content" padding="0">
          <CodeBlock>
            import &#123;SButton&#125; from "stainless-steel" &#160;
            <br />
            <br />
            return &#60;SButton&#62; Hello &#60;/SButton&#62;
          </CodeBlock>
        </SStud>
      </SRow>
      <SRow>
        <SColumn>
          <SButton>Hello</SButton>
          <SButton className="SButton-bullRed">Hello</SButton>
          <SButton className="SButton-forestGreen">Hello</SButton>
          <SButton className="SButton-prettyPink">Hello</SButton>
          <SButton className="SButton-capillary">Hello</SButton>
          <SButton className="SButton-bubbleGum">Hello</SButton>
          <SButton className="SButton-stemGreen">Hello</SButton>
        </SColumn>
        <SColumn>
          <CodeBlock width="70rem">
            &#60;SButton&#62;Hello&#60;/SButton&#62; <br /> &#60;SButton
            className="SButton-bullRed"&#62;Hello&#60;/SButton&#62;
            <br /> &#60;SButton
            className="SButton-forestGreen"&#62;Hello&#60;/SButton&#62;
            <br />
            &#60;SButton
            className="SButton-prettyPink"&#62;Hello&#60;/SButton&#62;
            <br />
            &#60;SButton
            className="SButton-capillary"&#62;Hello&#60;/SButton&#62;
            <br />
            &#60;SButton
            className="SButton-bubbleGum"&#62;Hello&#60;/SButton&#62;
            <br />
            &#60;SButton
            className="SButton-stemGreen"&#62;Hello&#60;/SButton&#62;
            <br />
          </CodeBlock>
        </SColumn>
      </SRow>
    </div>
  );
};

export default ButtonsDoc;
