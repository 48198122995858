import React from "react";

import { Yes } from "./lib";
import SButton from "./lib/components/buttons/SButton";
import SContainer from "./lib/components/containers/SContainer";

import "./lib/css/style.css";

import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Routes,
} from "react-router-dom";
import Project from "./lib/components/Project";
import SHeader from "./lib/components/Navigation/SHeader";
import Documentation from "./lib/components/Documentation";
import Css from "./lib/components/Css";

import GoogleFontLoader from "react-google-font-loader";

// Somewhere in your React tree:

const App = () => {
  return (
    <Router>
      <GoogleFontLoader
        fonts={[
          {
            font: "Baloo 2",
            weights: [400],
          },
        ]}
      />

      <SHeader>
        <div className="marginTop">
          <Link className="gavHeaderTitles marginLeft" to="/">
            home
          </Link>
          <Link className="gavHeaderTitles" to="/documentation/Intro">
            docs
          </Link>
          {/* <Link className="gavHeaderTitles" to="/css">
            css
          </Link> */}
        </div>
      </SHeader>
      <Routes>
        <Route path="/" element={<Project />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/documentation/:docId" element={<Documentation />} />
        <Route path="/css" element={<Css />} />
      </Routes>
    </Router>
  );
};

export default App;
