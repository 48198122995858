import React, { useEffect, useState } from "react";

const SContainer = (props) => {
  // const [toBe, setToBe] = useState();

  // useEffect(() => {
  //   if (!props.toBe) {
  //     //setToBe("SContainer-start");
  //     return;
  //   } else if (props.toBe) {
  //     const newSet = {
  //       minHeight: "100vh",
  //       maxHeight: "100%",
  //       maxWidth: "100%",
  //       minWidth: "fit-content",
  //       position: "relative",
  //     };
  //     setToBe(newSet);
  //   }
  // }, [props.toBe]);

  const [className, setClassName] = useState("SContainer");

  useEffect(() => {
    if (!props.className) {
      return;
    } else if (props.className) {
      setClassName(props.className);
    }
  }, [props.className]);

  const [style, setStyle] = useState();

  useEffect(() => {
    if (!props.style) {
      return;
    } else if (props.style) {
      setStyle((style) => ({
        ...style,
        ...props.style,
      }));
    }
  }, [props.style]);

  useEffect(() => {
    console.log(props.fontSize);
    if (!props.fontSize) {
      return;
    } else if (props.fontSize) {
      const aStyle = {
        fontSize: props.fontSize,
      };

      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.fontSize]);

  useEffect(() => {
    if (!props.fontWeight) {
      return;
    } else if (props.fontWeight) {
      const aStyle = {
        fontWeight: props.fontWeight,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.fontWeight]);

  useEffect(() => {
    if (!props.fontFamily) {
      return;
    } else if (props.fontFamily) {
      const aStyle = {
        fontFamily: props.fontFamily,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.fontFamily]);

  useEffect(() => {
    if (!props.verticalAlign) {
      return;
    } else if (props.verticalAlign) {
      const aStyle = {
        verticalAlign: props.verticalAlign,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.verticalAlign]);

  useEffect(() => {
    if (!props.textAlign) {
      return;
    } else if (props.textAlign) {
      const aStyle = {
        textAlign: props.textAlign,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.textAlign]);

  useEffect(() => {
    if (!props.margin) {
      return;
    } else if (props.margin) {
      const aStyle = {
        margin: props.margin,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.margin]);

  useEffect(() => {
    if (!props.marginLeft) {
      return;
    } else if (props.marginLeft) {
      const aStyle = {
        marginLeft: props.marginLeft,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.marginLeft]);
  useEffect(() => {
    if (!props.marginBottom) {
      return;
    } else if (props.marginBottom) {
      const aStyle = {
        marginBottom: props.marginBottom,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.marginBottom]);

  useEffect(() => {
    if (!props.marginTop) {
      return;
    } else if (props.marginTop) {
      const aStyle = {
        marginTop: props.marginTop,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.marginTop]);

  useEffect(() => {
    if (!props.marginRight) {
      return;
    } else if (props.marginRight) {
      const aStyle = {
        marginRight: props.marginRight,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.marginRight]);

  useEffect(() => {
    if (!props.width) {
      return;
    } else if (props.width) {
      const aStyle = {
        width: props.width,
        maxWidth: props.width,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.width]);

  useEffect(() => {
    if (!props.maxWidth) {
      return;
    } else if (props.maxWidth) {
      const aStyle = {
        maxWidth: props.maxWidth,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.maxWidth]);

  useEffect(() => {
    if (!props.minWidth) {
      return;
    } else if (props.minWidth) {
      const aStyle = {
        minWidth: props.minWidth,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.minWidth]);

  useEffect(() => {
    if (!props.color) {
      return;
    } else if (props.color) {
      const aStyle = {
        color: props.color,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.color]);

  useEffect(() => {
    if (!props.backgroundColor) {
      return;
    } else if (props.backgroundColor) {
      const aStyle = {
        backgroundColor: props.backgroundColor,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.backgroundColor]);

  useEffect(() => {
    if (!props.lineHeight) {
      return;
    } else if (props.lineHeight) {
      const aStyle = {
        lineHeight: props.lineHeight,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.lineHeight]);

  useEffect(() => {
    if (!props.paddingTop) {
      return;
    } else if (props.paddingTop) {
      const aStyle = {
        paddingTop: props.paddingTop,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.paddingTop]);
  useEffect(() => {
    if (!props.paddingBottom) {
      return;
    } else if (props.paddingBottom) {
      const aStyle = {
        paddingBottom: props.paddingBottom,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.paddingBottom]);

  useEffect(() => {
    if (!props.paddingRight) {
      return;
    } else if (props.paddingRight) {
      const aStyle = {
        paddingRight: props.paddingRight,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.paddingRight]);
  useEffect(() => {
    if (!props.paddingLeft) {
      return;
    } else if (props.paddingLeft) {
      const aStyle = {
        paddingLeft: props.paddingLeft,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.paddingLeft]);

  useEffect(() => {
    if (!props.padding) {
      return;
    } else if (props.padding) {
      const aStyle = {
        padding: props.padding,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.padding]);

  useEffect(() => {
    if (!props.height) {
      return;
    } else if (props.height) {
      const aStyle = {
        height: props.height,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.height]);

  useEffect(() => {
    if (!props.maxHeight) {
      return;
    } else if (props.maxHeight) {
      const aStyle = {
        maxHeight: props.maxHeight,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.maxHeight]);

  useEffect(() => {
    if (!props.minHeight) {
      return;
    } else if (props.minHeight) {
      const aStyle = {
        minHeight: props.minHeight,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.minHeight]);

  useEffect(() => {
    if (!props.overflow) {
      return;
    } else if (props.overflow) {
      const aStyle = {
        overflow: props.overflow,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.overflow]);

  useEffect(() => {
    if (!props.borderRadius) {
      return;
    } else if (props.borderRadius) {
      const aStyle = {
        borderRadius: props.borderRadius,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.borderRadius]);

  useEffect(() => {
    if (!props.codeblock) {
      return;
    } else if (props.codeblock) {
      const aStyle = {
        backgroundColor: "rgb(221, 221, 221)",
        fontFamily: "Courier New",
        fontWeight: "600",
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.codeblock]);

  useEffect(() => {
    if (!props.sidebar) {
      return;
    } else if (props.sidebar) {
      const aStyle = {
        display: "flex",
        // flexFlow: "column nowrap",
        // alignItems: "stretch",
        // justifyContent: "flex-start",
        // flexGrow: 1,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.sidebar]);

  useEffect(() => {
    console.log(props.backgroundConfig);
    if (!props.backgroundConfig) {
      return;
    } else if (props.backgroundConfig) {
      const aStyle = {
        backgroundImage: `url(${props.backgroundConfig.img})`,
        //objectFit: "cover",
        backgroundSize: "contain",
        width: props.backgroundConfig.width,
        height: props.backgroundConfig.height
          ? props.backgroundConfig.height
          : null,
        minHeight: !props.backgroundConfig.minHeight
          ? props.backgroundConfig.height
          : props.backgroundConfig.minHeight,
        maxHeight: !props.backgroundConfig.maxHeight
          ? props.backgroundConfig.height
          : props.backgroundConfig.maxHeight,
        //zIndex: 10,

        // flexFlow: "column nowrap",
        // alignItems: "stretch",
        // justifyContent: "flex-start",
        // flexGrow: 1,
      };
      setStyle((style) => ({
        ...style,
        ...aStyle,
      }));
    }
  }, [props.backgroundConfig]);

  return (
    <div style={style} className={className}>
      {props.children}
    </div>
  );
};

export default SContainer;
