import React from "react";
import { SContainer } from "../..";
import CodeBlock from "../containers/CodeBlock";
import SColumn from "../containers/SColumn";
import SRow from "../containers/SRow";
import SStud from "../containers/SStud";
import STitle from "../titles/STitle";

const StudsDoc = (props) => {
  return (
    <div>
      <SContainer>
        <SRow textAlign="left">
          <SColumn>
            <SStud>
              <STitle
                fontFamily="Courier New"
                color="blue"
                display="block"
                fontSize="2.3rem"
                fontWeight="600"
              >
                Studs
              </STitle>
              <STitle>
                Stud's are for inlining block's of code within Columns.
              </STitle>
            </SStud>
          </SColumn>
        </SRow>
        <SRow>
          <CodeBlock width="70rem" textAlign="left">
            import &#123;SStud&#125; from "stainless-steel";
            <br />
            ...
            <br />
            &#60;SStud&#62; &#60;/SStud&#62;
          </CodeBlock>
        </SRow>
      </SContainer>
    </div>
  );
};

export default StudsDoc;
